import { AuthData } from "../../model/auth";
import { RootState } from "../../redux/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: AuthData = {
  theme: localStorage?.getItem("user_theme") || "dark",
  userData: JSON.parse(localStorage?.getItem("user") || "null") as string,
  token: localStorage.getItem("jwt_token") || null,
  isLoggedIn: localStorage.getItem("user") ? true : false,
  error: null,
  balance: null,
  exposure: null,
  netBalance: null,
  currencyName: "",
  isBalanceLoading: false,
  isSearch: false,
  isLoginModal: false,
  isOpenOneClick: false,
};

const authSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    updateBalanceLoading: (state, action: PayloadAction<any>) => {
      state.isBalanceLoading = action?.payload;
    },
    balanceSuccess: (state, action: any) => {
      state.balance = action.payload.Balance;
      state.exposure = action.payload.Exposure;
      state.netBalance = action.payload.NetBalance;
      state.currencyName = action.payload.CurrencyName;
      state.isBalanceLoading = false;
    },
    loginSuccess: (state, action: any) => {
      localStorage.setItem("user", JSON?.stringify(action?.payload));
      localStorage.setItem("jwt_token", action?.payload?.Token);
      state.userData = action.payload;
      state.token = action.payload.Token;
      state.isLoggedIn = true;
      state.error = null;
      state.isLoginModal = false;
    },
    loginFailure: (state, action: any) => {
      state.userData = null;
      state.isLoggedIn = false;
      state.error = action.payload;
      state.balance = null;
    },
    logout: (state) => {
      localStorage.clear();
      state.userData = null;
      state.isLoggedIn = false;
      state.token = null;
      state.error = null;
      state.balance = null;
    },
    changePasswordFailure: (state, action: any) => {
      state.error = action.payload;
    },
    changeTheme: (state) => {
      state.theme = state.theme === "light" ? "dark" : "light";
      localStorage.setItem("user_theme", state.theme);
    },
    handleSearch: (state) => {
      state.isSearch = !state.isSearch;
    },
    handleLoginModal: (state) => {
      state.isLoginModal = !state.isLoggedIn ? !state.isLoginModal : false;
    },
    handleOneClickBetting: (state) => {
      state.isOpenOneClick = !state.isOpenOneClick
        ? !state.isOpenOneClick
        : false;
    },
    updateUserData: (state, action: PayloadAction<any>) => {
      const updatedUserData = action.payload;
      localStorage.setItem("user", JSON.stringify(updatedUserData));
      state.userData = updatedUserData;
    },
  },
});

export const {
  loginSuccess,
  loginFailure,
  logout,
  changePasswordFailure,
  balanceSuccess,
  changeTheme,
  handleSearch,
  handleLoginModal,
  handleOneClickBetting,
  updateUserData,
  updateBalanceLoading,
} = authSlice.actions;
export const userData = (state: RootState) => state.auth.userData;
export const isLoggedIn = (state: RootState) => state.auth.isLoggedIn;

export default authSlice.reducer;
