import API from "../../api";
import { sportIds } from "../../common/exchange";
import { AxiosResponse } from "axios";

import PLACEBETAPI from "../../placeBetApi";
export const getCompetitions = async (sportId: string = "4") => {
  let sportType: any = sportIds[sportId as keyof typeof sportIds];
  try {
    const response = await API.get(
      `/client/v1/sports/getSportCompetitions?eventType=${sportType}`
    );
    return response.data;
  } catch (error) {}
};
export const getEvents = async (sportId: string, competitionId: string) => {
  let sportType: any = sportIds[sportId as keyof typeof sportIds];
  try {
    const response = await API.get(
      `/client/v1/sports/getCompetitionEvents?eventType=${sportType}&competitionId=${competitionId}`
    );
    return response.data;
  } catch (error) {}
};


export const getHREvents = async (eventDay: string) => {
  try {
    const response = await API.get(
      `/client/v1/sports/getHorseEvents?eventDay=${eventDay}`
    );
    return response.data;
  } catch (error) {}
};
export const getHRUpcomingEvents = async () => {
  try {
    const response = await API.get(
      `/client/v1/sports/getUpcomingHorseEvents`
    );
    return response.data;
  } catch (error) {}
};


export const getMarket = async (
  sportId: string,
  competitionId: string,
  eventId: string
) => {
  let sportType: any = sportIds[sportId as keyof typeof sportIds];
  try {
    const response = await API.get(
      `/client/v1/sports/getEventMarkets?eventType=${sportType}&competitionId=${competitionId}&eventId=${eventId}`
    );
    return response.data;
  } catch (error) {}
};

export const getEventsBySport = async (
  sportId: string = "cricket",
  sortBy?: string
) => {
  try {
    const sportType: any = sportIds[sportId as keyof typeof sportIds];
    if (sortBy === "competition") {
      const response = await API.get(
        `/client/v1/sports/getSportEvents?eventType=${sportType}&viewBy=${sortBy}`
      );
      return response?.data?.data;
    } else {
      const response = await API.get(
        `/client/v1/sports/getSportEvents?eventType=${sportType}`
      );
      return response?.data?.data ?? [];
    }
  } catch (error) {
    // Handle the error here
  }
};

export const getMarketData = async (payload: any) => {
  const { EventId, SportId } = payload;
  try {
    const response = await API.get(
      `/client/v1/sports/getFullMarket?eventId=${EventId}&sportId=${SportId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchSportCount = () => {
  return API.get(`/client/v1/sports/getLiveEventsCount`);
};

export const featchSearchEvents = async (searchKey: string) => {
  if (searchKey) {
    const response = await API.get(
      `/client/v1/sports/getSearchEvents?search=${searchKey}`
    );
    return response.data;
  }
};

export const getInplayEvents = async (
  isInplay?: boolean,
  filterBy?: string
) => {
  const url = isInplay
    ? `/client/v1/sports/getAllEvents?inPlay=true`
    : `/client/v1/sports/getAllEvents?filterBy=${filterBy}`;
  const response = await API.get(url);
  return response.data;
};

export const placeBet = async (payload: any): Promise<AxiosResponse> => {
  try {
    const response = await PLACEBETAPI.post(
      `/client/v1/exchange/placebet`,
      payload[0]
    );
    return response?.data;
  } catch (error) {
    throw error; // Re-throw the error to propagate it up to the caller
  }
};
export const horseRacingplaceBet = async (payload: any): Promise<AxiosResponse> => {
  try {
    const response = await PLACEBETAPI.post(
      `/client/v1/exchange/placebetHR`,
      payload[0]
    );
    return response?.data;
  } catch (error) {
    throw error; // Re-throw the error to propagate it up to the caller
  }
};

export const fancyPlaceBet = async (payload: any): Promise<AxiosResponse> => {
  try {
    const response = await PLACEBETAPI.post(
      `/client/v1/exchange/fancyplacebet`,
      payload[0]
    );
    return response?.data;
  } catch (error) {
    // Handle error here
    console.error("Error placing bet:", error);
    throw error; // Re-throw the error to propagate it up to the caller
  }
};

export const getOpenBets = (): Promise<AxiosResponse> => {
  return API.get("/client/v1/exchange/getOpenBets");
};

export const getEventOpenBets = (eventId: string): Promise<AxiosResponse> => {
  return API.get("/client/v1/exchange/getEventOpenBets?eventId=" + eventId);
};

export const updateCoinValues = async (
  payload: any
): Promise<AxiosResponse> => {
  try {
    const response = await API.put(
      `/client/v1/player/updateCoinValues`,
      payload
    );
    return response?.data;
  } catch (error) {
    throw error; // Re-throw the error to propagate it up to the caller
  }
};

export const cancelUnMatchBets = (payload: any): Promise<AxiosResponse> => {
  return API.put(`/client/v1/exchange/cancelUnMatchBets`, payload);
};

export const getMultiMarketList = (): Promise<AxiosResponse> => {
  return API.get(`/client/v1/multimarket/getMultiMrket`);
};

export const addToMultiMarket = (payload: any): Promise<AxiosResponse> => {
  return API.post(`/client/v1/multimarket/addMultimarket`, payload);
};

export const removeFromMultiMarket = (payload: any): Promise<AxiosResponse> => {
  return API.post(`/client/v1/multimarket/removeMultiMarket`, payload);
};

export const getFancyBookData = async (
  payload: any
): Promise<AxiosResponse> => {
  try {
    const response = await API.post(
      `/client/v1/exchange/getFancyBook`,
      payload
    );
    return response?.data?.data;
  } catch (error) {
    throw error; // Re-throw the error to propagate it up to the caller
  }
};
