import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isMobile } from "./lib/common";
import { useAppSelector } from "./redux/hooks";
import LoginModal from "./web/layout/header/loginModal";
import "./App.css";

const WebLayout = React.lazy(() => import("./web/layout"));
const MobileLayout = React.lazy(() => import("./mobile/layout"));

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useAppSelector((state: any) => state.auth);

  useEffect(() => {
    if (window?.performance && isMobile) {
      if (performance?.navigation?.type === 1) {
        // console.log('Page was reloaded');
        navigate("/");
      }
    }
  }, []);

  useEffect(() => {
    let systemDark = window.matchMedia(
      `(prefers-color-scheme: ${user?.theme})`
    );

    toggleTheme(systemDark);
    systemDark.addListener((e) => toggleTheme(e));

    return () => {
      systemDark.removeListener(toggleTheme);
    };
  }, [user?.theme]);

  const toggleTheme = (systemInitiatedDark: any) => {
    if (systemInitiatedDark.matches) {
      document.body.setAttribute("data-theme", "dark");
    } else {
      document.body.setAttribute("data-theme", "light");
    }
  };

  return (
    <React.Fragment>

      {user?.isLoginModal ? (
        <div id="loginBox" className="overlay" style={{ display: "block", zIndex: "1000000" }}>
          <LoginModal />
        </div>
      ) : null}

      {isMobile && !location.pathname?.includes("/account/") ? (
        <div>
          <MobileLayout />
        </div>
      ) : (
        <div>
          <WebLayout />
        </div>
      )}

    </React.Fragment>
  );
};

export default App;
