export type SportType = {
  cricket: string;
  soccer: string;
  tennis: string;
  basketball: string;
  horseracing: string;
};

export const sportIds = {
  cricket: 4,
  tennis: 2,
  soccer: 1,
  basketball: 3,
  esoccer: 1,
  horseracing: 7,
};
export const sportNameByIds = {
  4: "Cricket",
  2: "Tennis",
  1: "Soccer",
  3: "Basketball",
  7: "Horseracing",
} as const;

export function getSportNameByIds(sportId: keyof typeof sportNameByIds): any {
  return sportNameByIds[sportId];
}

export function getSportId(
  sportId: keyof SportType
): number | string | undefined {
  return sportIds[sportId];
}

export const premiumType = [
  "All",
  "Popular",
  "Match",
  "Innings",
  "Over",
  "Batsman",
  "Partnership",
  "Group",
  "Range",
  "Head-to-Head",
  "Player",
];

export const sportNameById = {
  "4": "Cricket",
  "2": "Tennis",
  "1": "Soccer",
  "3": "Basketball",
  "7": "Horseracing",
} as const;
