import React from 'react';
import Logo from "../../logo.png"
import "./loader.css";

const Loader = () => {
    return (
        <div className="preloader">
            <img src={Logo} alt="logo" />
            {/* <div className="loader"></div> */}
        </div>
    )
}

export default Loader;
