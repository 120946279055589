import React, { useState, useEffect } from "react";
import { loginUser } from "../../../redux/auth/authApi";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { LoginPayload, AuthData } from "../../../model/auth";
import { encryptText } from "../../../common/config";
import { handleLoginModal } from "../../../redux/index";
import download from "../../images/download-yellow.svg";
import "./login.css";

interface FormData {
  id: string;
  password: string;
  validationCode: string;
  userEnteredCode: string;
  error: string | null;
}

const LoginModal: React.FC = () => {
  const dispatch: ThunkDispatch<AuthData, LoginPayload, AnyAction> =
    useDispatch();
  const user = useSelector((state: any) => state.auth);

  const [formData, setFormData] = useState<FormData>({
    id: "",
    password: "",
    validationCode: generateRandomCode(),
    userEnteredCode: "",
    error: null,
  });

  useEffect(() => {
    // Automatically change the validation code
    const interval = setInterval(() => {
      setFormData((prevData) => ({
        ...prevData,
        validationCode: generateRandomCode(),
      }));
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleLogin = async () => {
    console.log("@@@");
    if (!formData.id || !formData.password) {
      setFormData({ ...formData, error: "Username and Password are required" });
    } else if (!formData.userEnteredCode) {
      setFormData({ ...formData, error: "Validation code is required" });
    } else if (formData.userEnteredCode !== formData.validationCode) {
      setFormData({ ...formData, error: "Validation code does not match" });
    } else {
      const payload: LoginPayload = {
        LoginId: formData.id,
        Password: encryptText(formData.password),
      };
      try {
        await dispatch(loginUser(payload));
      } catch (error) {
        console.error("Login failed:", error);
      }
      setFormData({ ...formData, error: null });
    }
  };

  function generateRandomCode() {
    return Math.floor(1000 + Math.random() * 9000).toString();
  }
  const handleCopy = (e: any) => e.preventDefault();
  const handleCut = (e: any) => e.preventDefault();
  const handlePaste = (e: any) => e.preventDefault();
  const handleDrag = (e: any) => e.preventDefault();
  const handleDrop = (e: any) => e.preventDefault();

  return (
    <>
      <div
        className="modal fade form-modal-bg form-modal-bg-setting automatic-width"
        id="exampleModal5"
        aria-labelledby="exampleModalLabel1"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body form-modal-bg-body">
              <div className="dialog-wrap-w login_to_go">
                <div className="kv"></div>
                <dl className="login-panel">
                  <dt>Please login to continue</dt>
                  <dd>
                    <input
                      type="text"
                      placeholder="Username"
                      name="id"
                      value={formData.id}
                      onChange={handleInputChange}
                    />
                  </dd>
                  <dd>
                    <input
                      type="password"
                      placeholder="Password"
                      name="password"
                      value={formData.password}
                      onChange={handleInputChange}
                    />
                  </dd>
                  <dd className="valid-code mr-2">
                    <input
                      type="text"
                      placeholder="Validation Code"
                      maxLength={4}
                      name="userEnteredCode"
                      value={formData.userEnteredCode}
                      onChange={handleInputChange}
                    />
                    <div className="validation-code-modal" onCopy={handleCopy}
                        onCut={handleCut}
                        onPaste={handlePaste}
                        onDrag={handleDrag}
                        onDrop={handleDrop}
                        style={{ userSelect: "none" }}>
                      {formData.validationCode}
                    </div>
                  </dd>
                  <dd>
                    <a className="btn-send" onClick={() => handleLogin()}>
                      Login
                      <img
                        className="icon-login"
                        src={download}
                        alt="Download Icon"
                      />
                    </a>
                  </dd>
                  <dd className="error">
                    {formData.error && <>{formData.error}</>}
                    {user?.error && <>{user.error}</>}
                  </dd>
                </dl>
                <button
                  type="button"
                  className="btn-content"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => dispatch(handleLoginModal())}
                >
                  <a className="close"></a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginModal;
