import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  loginSuccess,
  loginFailure,
  logout,
  changePasswordFailure,
  balanceSuccess,
  updateBalanceLoading,
} from "./authSlice";
import API from "../../api";
import { LoginPayload, ChangePasswordPayload } from "../../model/auth"; // Correct the typo 'modal' to 'model' if needed
import { AxiosResponse } from "axios";

export const loginUser = createAsyncThunk(
  "auth/login",
  async (payload: LoginPayload, { dispatch }) => {
    try {
      const response = await API.post<any>(
        "/client/v1/auth/clientlogin",
        payload
      );
      if (response) {
        if (response?.data?.data?.ChangePassword) {
          window.location.href = "/change-password";
        }
        dispatch(loginSuccess(response?.data?.data));
      }
    } catch (error: any) {
      dispatch(loginFailure(error));
    }
  }
);
export const changePassword = createAsyncThunk(
  "auth/changePassword",
  async (payload: ChangePasswordPayload, { dispatch }) => {
    try {
      const response = await API.post<any>(
        "/client/v1/auth/changePassword",
        payload
      );
      if (response) {
        dispatch(logout());
        window.location.href = "/";
      }
    } catch (error: any) {
      dispatch(changePasswordFailure(error));
    }
  }
);
export const getUserBalance = createAsyncThunk(
  "auth/getBalance",
  async (_, { dispatch }) => {
    try {
      const response = await API.get<any>("/client/v1/balance/getbalance");
      if (response) {
        dispatch(balanceSuccess(response?.data?.data));
      }
    } catch (error: any) {
      dispatch(updateBalanceLoading(false));
      dispatch(logout());
    }
  }
);

export const getProfileData = (): Promise<AxiosResponse> => {
  return API.get("/client/v1/player/getPlayerInfo");
};

export const updateProfileData = (payload: any): Promise<AxiosResponse> => {
  return API.post("/client/updateprofile", payload);
};

// export const getActivityLogData = (payload: any): Promise<AxiosResponse> => {
//   return API.post("/client/activitylog", payload);
// };
export const getActivityLogData = (page: number): Promise<AxiosResponse> => {
  // Adding pageNumber to the payload
  const updatedPayload = {
    page: page,
  };
  // Making the API call with the updated payload
  return API.get("/client/v1/reporting/activitylog", {
    params: updatedPayload,
  });
};

export const getBetHistory = (payload: any): Promise<AxiosResponse> => {
  const { page, ...restPayload } = payload;
  return API.post(
    `/client/v1/exchange/getBetHistory?page=${payload.page}`,
    restPayload
  );
};
export const getCurrentbets = (payload: any): Promise<AxiosResponse> => {
  const { page, ...restPayload } = payload;
  return API.post(
    `/client/v1/exchange/getCurrentbets?page=${payload.page}`,
    restPayload
  );
};

export const getBookmakerBestData = (payload: any): Promise<AxiosResponse> => {
  return API.post("/client/bookmakerbethistory", payload);
};

export const getFancyBestData = (payload: any): Promise<AxiosResponse> => {
  return API.post("/client/currentfancybet", payload);
};

export const getSportBetsData = (payload: any): Promise<AxiosResponse> => {
  return API.post("/client/currentsportsbookbet", payload);
};

// export const getBalanceOverview = (payload: any): Promise<AxiosResponse> => {
//   return API.post("/client/balanceoverview", payload);
// };
export const getBalanceOverview = (page: number): Promise<AxiosResponse> => {
  const updatedPayload = {
    page: page,
  };
  return API.get("/client/v1/balance/balanceoverview", {
    params: updatedPayload,
  });
};

export const getAccountStatementData = (
  page: number
): Promise<AxiosResponse> => {
  const updatedPayload = {
    page: page,
  };
  return API.get("/client/v1/reporting/accountstatement", {
    params: updatedPayload,
  });
};

export const getProfitLossData = (payload: any): Promise<AxiosResponse> => {
  const { page, ...restPayload } = payload;
  return API.post(
    `/client/v1/exchange/getProfitLoss?page=${payload.page}`,
    restPayload
  );
};
export const getCasinoProfitLossData = (payload: any): Promise<AxiosResponse> => {
  const { page, ...restPayload } = payload;
  return API.post(
    `/client/v1/exchange/getCasinoProfitLoss?page=${payload.page}`,
    restPayload
  );
};
export const getCasinoFullProfitLossData = (payload: any): Promise<AxiosResponse> => {
  const { page, ...restPayload } = payload;
  return API.post(
    `/client/v1/exchange/getCasinoPLDetails?page=${payload.page}`,
    restPayload
  );
};

export const handleLogout = createAsyncThunk(
  "auth/logout",
  async (_, { dispatch }) => {
    try {
      const response = await API.patch<any>("/client/v1/auth/logout");
      if (response) {
        dispatch(logout());
        window.location.href = "/";
      }
    } catch (error: any) {}
  }
);
