import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";
import {
  fetchSportCount,
  getOpenBets,
  getEventOpenBets,
  cancelUnMatchBets,
  getMultiMarketList,
  addToMultiMarket,
  removeFromMultiMarket,
} from "./exchangeApi";
import { getUserBalance } from "../index";
import { SportCounts } from "../../model/exchange";

export interface ExchangeState {
  bets: any[];
  sportCount: SportCounts;
  openBets: any[];
  eventOpenBets: any[];
  betsMsg: any;
  multiMarketList: any[];
}

const initialState: ExchangeState = {
  bets: [],
  sportCount: {},
  openBets: [],
  eventOpenBets: [],
  betsMsg: { show: false },
  multiMarketList: [],
};

export const fetchSportCounts = createAsyncThunk(
  "exchange/fetchSportCount",
  async () => {
    try {
      const response: any = await fetchSportCount();
      return response?.data?.data ?? {};
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }
);

export const featchOpenBets = createAsyncThunk(
  "exchange/getOpenBets",
  async () => {
    // Specify the type of the payload
    try {
      const response = await getOpenBets();
      return response?.data?.data ?? [];
    } catch (error) {
      console.log(error);
      throw error; // Use throw to propagate the error in async thunk
    }
  }
);

export const featchEventOpenBets = createAsyncThunk(
  "exchange/getEventOpenBets",
  async (eventId: string) => {
    // Specify the type of the payload
    try {
      const response = await getEventOpenBets(eventId);
      return response?.data?.data ?? [];
    } catch (error) {
      console.log(error);
      throw error; // Use throw to propagate the error in async thunk
    }
  }
);

export const cancelUnMatchBetsApi = createAsyncThunk(
  "exchange/cancelUnMatchBets",
  async (payload: any, { dispatch }) => {
    // Specify the type of the payload
    try {
      const response = await cancelUnMatchBets(payload);
      if (response?.data?.data?.length) {
        dispatch(getUserBalance());
        dispatch(featchOpenBets());
      }
      return response?.data?.data ?? [];
    } catch (error) {
      console.log(error);
      throw error; // Use throw to propagate the error in async thunk
    }
  }
);

export const fetchMultiMarketList = createAsyncThunk(
  "exchange/fetchMultiMarketList",
  async () => {
    try {
      const response = await getMultiMarketList();
      return response?.data?.data ?? [];
    } catch (error) {
      console.log(error);
      throw error; // Use throw to propagate the error in async thunk
    }
  }
);

export const addMultiMarketItem = createAsyncThunk(
  "exchange/addMultiMarketItem",
  async (payload: any, { dispatch }) => {
    try {
      dispatch(upDateMultiMarketItem({ type: "add", data: payload }));
      const response = await addToMultiMarket(payload);
      if (response.data.status !== "success") {
        //dispatch(fetchMultiMarketList());
        dispatch(upDateMultiMarketItem({ type: "remove", data: payload }));
      }
    } catch (error) {
      console.log(error);
      dispatch(upDateMultiMarketItem({ type: "remove", data: payload }));
      throw error; // Use throw to propagate the error in async thunk
    }
  }
);

export const removeMultiMarketItem = createAsyncThunk(
  "exchange/removeMultiMarketItem",
  async (payload: any, { dispatch }) => {
    try {
      dispatch(upDateMultiMarketItem({ type: "remove", data: payload }));
      const response = await removeFromMultiMarket(payload);
      if (response.data.status !== "success") {
        //dispatch(fetchMultiMarketList());
        dispatch(upDateMultiMarketItem({ type: "add", data: payload }));
      }
    } catch (error) {
      console.log(error);
      dispatch(upDateMultiMarketItem({ type: "add", data: payload }));
      throw error; // Use throw to propagate the error in async thunk
    }
  }
);

export const exchangeSlice = createSlice({
  name: "exchange",
  initialState,
  reducers: {
    addBetsData: (state, action: PayloadAction<any>) => {
      state.bets = [action.payload];
    },
    addBetsMsg: (state, action: PayloadAction<any>) => {
      state.betsMsg = {
        ...action.payload,
        show: true,
      };
    },
    clearBetsMsg: (state, action: PayloadAction<any>) => {
      state.betsMsg = { show: false };
    },
    updateBetsData: (state, action: PayloadAction<any>) => {
      const { index, key, value } = action.payload;
      const updatedBets = state.bets.map((bet, i) => {
        if (i === index) {
          return { ...bet, [key]: value };
        } else {
          return bet;
        }
      });
      state.bets = updatedBets;
    },
    clearBetsData: (state) => {
      state.bets = [];
    },
    upDateMultiMarketItem: (state, action: PayloadAction<any>) => {
      if (action.payload.type === "add")
        state.multiMarketList = [...state.multiMarketList, action.payload.data];
      else {
        state.multiMarketList = [
          ...state.multiMarketList.filter(
            (item: any) => item?.MarketId !== action.payload.data?.MarketId
          ),
        ];
      }
    },
    // removeMultiMarketItem: (state, action: PayloadAction<any>) => {
    //   const { index } = action.payload;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSportCounts.fulfilled, (state, action) => {
        state.sportCount = { ...action.payload };
      })
      .addCase(fetchSportCounts.rejected, (state, action) => {
        state.sportCount = {};
        console.error("Fetch sport counts failed:", action.error);
      })
      .addCase(featchOpenBets.fulfilled, (state, action) => {
        state.openBets = [...action.payload];
      })
      .addCase(featchOpenBets.rejected, (state, action) => {
        state.openBets = [];
        console.error("Fetch open bets failed:", action.error);
      })
      .addCase(featchEventOpenBets.fulfilled, (state, action) => {
        state.eventOpenBets = [...action.payload];
      })
      .addCase(featchEventOpenBets.rejected, (state, action) => {
        state.eventOpenBets = [];
        console.error("Fetch open bets failed:", action.error);
      })
      .addCase(fetchMultiMarketList.fulfilled, (state, action) => {
        state.multiMarketList = [...action.payload];
      })
      .addCase(fetchMultiMarketList.rejected, (state, action) => {
        state.multiMarketList = [];
        console.error("Fetch open bets failed:", action.error);
      });
  },
});

export const {
  addBetsData,
  clearBetsData,
  updateBetsData,
  addBetsMsg,
  clearBetsMsg,
  upDateMultiMarketItem,
} = exchangeSlice.actions;

export const betsMsg = (state: RootState) => state.exchange.betsMsg;
export const bets = (state: RootState) => state.exchange.bets;
export const sportCount = (state: RootState) => state.exchange.sportCount;
export const openBets = (state: RootState) => state.exchange.openBets;
export const eventOpenBets = (state: RootState) => state.exchange.eventOpenBets;
export const multiMarketList = (state: RootState) =>
  state.exchange.multiMarketList;

export default exchangeSlice.reducer;
